





















































































































































































































































































































































































































































































































































































































































import Vue from "vue";
/* import { Container, Draggable } from "vue-smooth-dnd"; */
/* import LeadDashboardGroup from "./LeadDasboardGroup.vue"; */
import rules from "@/components/account/rules";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import SendEmail from "@/components/emails/SendEmail.vue";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import TableCalls from "@/views/Configurations/components/TableCalls.vue";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { Lead, LeadStatus } from "@/models/Lead";
import formatPhone from "@/utils/formatPhone";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { debounce } from "lodash";
import moment from "moment";
import AllImages from "@/components/Images/AllImages.vue";
export default Vue.extend({
  components: {
    /*  LeadDashboardGroup, */
    /* Draggable, */
    /*  Container, */
    SendEmail,
    MaDatePicker,
    DrawerSms,
    TableLeads,
    TableCalls,
    BtnMakeCall,
    AsignTo,
    AllImages,
  },
  data: () => ({
    loadingAcciones: false,
    deleteL: {
      deleteItem: {},
      deleteDialog: false,
    },
    showcalendar: false,
    changed: false,
    formValid: false,
    edit: {
      dialogEdit: false,

      itemEdit: {
        leadUuid: "",
        status: null,
        howKnow: "",
        name: "",
        last_name: "",
        phone: "",
        email: "",
      },
    },

    tabSelect: null,
    title: "",
    footerProps: { "items-per-page-options": [15, 100, 500, 1000] },
    details: {},

    rules: {
      email: rules.email,
      required: rules.required,
      phone: rules.isPhone,
      maxCharsfull: (v: string) =>
        v.length >= 3 || (this as any).$t("min3Chars"),
    },
    options: {},
    dialogNote: false,
    dialogLead: false,
    selected: [],
    detailsnote: "",
    newlead: {
      howKnow: "",
      name: "",
      last_name: "",
      phone: "",
      email: "",
    },
    dateTo: "",
    dateFrom: "",
    uuidLead: "",
    body: {
      query: null,
      dates: null,
      status: null,
      noCoordinator: false,
      coordinatorUuid: null,
      interval: {
        offset: 10,
        limit: 0,
      },
    },
    upperDropPlaceholderOptions: {
      className: "cards-drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    expanded: [],
    status: [
      { name: "Callback", value: "Callback" },
      { name: "Text", value: "Text" },
      { name: "Interested", value: "Interested" },
      { name: "Not Interested", value: "NotInterested" },
      { name: "Wrong Number", value: "WrongNumber" },
      { name: "Pending", value: "Pending" },
    ],

    cards: [
      {
        id: "NEW",
        title: "New",
        color: "#ab47bc",
        children: [],
      },
      {
        id: "EVALUATION",
        title: "Evaluation",
        color: "#ec407a",
        children: [],
      },
      {
        id: "NO_INTERESTED",
        title: "Not interested",
        color: "#e91e63",
        children: [],
      },
      {
        id: "PENDING",
        title: "Pending",
        color: "#f44336",
        children: [],
      },
    ],
  }),
  computed: {
    ...mapGetters(["getCoordinatorN", "getpatientN"]),
    ...mapState("crmConfigurationsModule", ["leads", "loading", "totalleads"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),
    ...mapState("crmEmployeeModule", ["coordinators"]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapState("crmCallCenterModule", ["callsLead"]),
    dateRangeText() {
      let temp = (this as any).body.dates;
      if ((this as any).body.dates.length > 1) {
        const val1 = (this as any).body.dates[0].split("-").splice(2, 1);
        const val2 = (this as any).body.dates[1].split("-").splice(2, 1);
        if (Number(val1) > Number(val2)) {
          temp = [];
          temp.push((this as any).body.dates[1]);
          temp.push((this as any).body.dates[0]);
        }
      }
      return temp.join(" ~ ");
    },

    Createvalid() {
      if (
        this.newlead.name != "" &&
        this.newlead.last_name != "" &&
        this.newlead.email != "" &&
        this.newlead.phone != "" &&
        this.newlead.howKnow != ""
      ) {
        return true;
      }
      return false;
    },
    height() {
      const style = {
        height: `${
          this.$vuetify.breakpoint.height -
          64 * 3 -
          (this.$vuetify.breakpoint.lgAndUp ? 25 : 15)
        }px !important`,
      };

      return style;
    },

    headers() {
      return [
        {
          text: "NAME",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "EMAIL", value: "email" },
        { text: "SOURCE", value: "howKnow" },
        { text: "PHONE", value: "phone" },
        { text: this.getCoordinatorN, value: "coordinator" },
        { text: "STATUS", value: "status" },
        { text: "CALLS", value: "callCount", align: "center" },
        { text: "SMS", value: "smsCount" },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  mounted() {
    (this as any).actListReferences();
    (this as any).actGetCoordinators();
  },
  watch: {
    expanded(val) {
      if (val != []) {
        (this as any).expandedLead();
      }
    },
    options(val) {
      if (val != {}) {
        (this as any).getLeads();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        (this as any).getLeads();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        (this as any).getLeads();
      }
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetCoordinators"]),
    ...mapActions("crmCallCenterModule", ["actFilterCalls"]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    statusShow(item: Lead) {
      if (item.status == null || item.status == undefined) {
        return "-";
      }
      switch (item.status) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return item.status;
      }
    },

    convertToPatient(lead: any) {
      (this as any).mutleadSelect(lead);
      this.$router.push("/patients/create");
    },
    async expandedLead() {
      if ((this as any).expanded.length != 0) {
        this.uuidLead = (this as any).expanded[0].uuid;
        this.details = (this as any).expanded[0];
        const phone = (this as any).expanded[0].phone;
        await (this as any).actFilterCalls({
          whateverNumber: phone,
          limit: 500,
          offset: 0,
        });
      } else {
        this.uuidLead = "";
        this.details = {};
      }
    },
    toEdit(item: any) {
      let pho = item.phone;
      if (pho.includes("+1")) {
        pho = pho.replace("+1", "");
      }
      pho = pho.replaceAll("\t", "");
      pho = pho.replaceAll(" ", "");
      pho = formatPhone(pho);

      this.edit.itemEdit = {
        leadUuid: item.uuid,
        name: item.name,
        last_name: item.last_name,
        phone: pho,
        email: item.email,
        howKnow: item.howKnow,
        status: item.status,
      };
      this.edit.dialogEdit = true;
    },
    cancelEdit() {
      this.edit.dialogEdit = false;
      this.edit.itemEdit = {
        leadUuid: "",
        status: null,
        howKnow: "",
        name: "",
        last_name: "",
        phone: "",
        email: "",
      };
    },
    confirmEdit() {
      (this as any).loadingAcciones = true;
      const body = this.edit.itemEdit;
      body.phone = "+1" + body.phone;
      getAPI
        .put("/social/updateCounts", this.edit.itemEdit)
        .then(() => {
          notifySuccess("Lead has been Edited");
          (this as any).loadingAcciones = false;
          (this as any).cancelEdit();
          (this as any).getLeads();
        })
        .catch((error: any) => {
          (this as any).loadingAcciones = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    toIsoString(values: string[]) {
      let temp = [];
      if (values.length == 1 || values[0] == values[1]) {
        const date1 = new Date(values[0]);
        const date2 = new Date(values[0]);
        date2.setUTCHours(23, 59);
        temp.push(date1.toISOString());
        temp.push(date2.toISOString());
      } else {
        values.forEach((val) => {
          const date = new Date(val);
          temp.push(date.toISOString());
        });
      }

      return temp;
    },

    toDelete(item: any) {
      this.deleteL.deleteDialog = true;
      this.deleteL.deleteItem = item;
    },
    cancelDelete() {
      this.deleteL = { deleteItem: {}, deleteDialog: false };
    },
    confirmDelte() {
      (this as any).loadingAcciones = true;
      getAPI
        .delete("/social/delete/" + (this as any).deleteL.deleteItem.uuid)
        .then(() => {
          notifyInfo("Lead has been deleted");
          (this as any).loadingAcciones = false;
          (this as any).cancelDelete();
          (this as any).getLeads();
        })
        .catch((error: any) => {
          (this as any).loadingAcciones = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getLeads() {
      this.selected = [];
      let reqbody: any = {};

      reqbody = {
        query: this.body.query,
        status: this.body.status,
        noCoordinator: this.body.noCoordinator,
        coordinatorUuid: this.body.coordinatorUuid,
        interval: this.body.interval,
      };
      if (
        (this.dateTo != "" &&
          this.dateTo != null &&
          this.dateTo != undefined &&
          this.dateFrom != "" &&
          this.dateFrom != null &&
          this.dateFrom != undefined) ||
        this.body.dates != null
      ) {
        if (this.body.dates != null) {
          reqbody = { ...reqbody, dateInterval: this.body.dates };
        } else {
          reqbody = {
            ...reqbody,
            dateInterval: {
              date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
              date2: moment(this.dateTo).endOf("day").utc().toISOString(),
            },
          };
        }
      }
      const { page, itemsPerPage }: any = this.options;
      const itmper = itemsPerPage != undefined ? itemsPerPage : 15;
      const p = page != undefined ? page : 1;
      const reqrange = {
        limit: itmper,
        offset: (p - 1) * itmper,
      };
      reqbody.interval = reqrange;
      this.body = {
        query: reqbody.query,
        status: reqbody.status,
        noCoordinator: reqbody.noCoordinator,
        interval: reqbody.interval,
        dates: reqbody.dateInterval ? reqbody.dateInterval : null,
        coordinatorUuid: reqbody.coordinatorUuid,
      };
      reqbody = (this as any).cleanNull(reqbody);
      (this as any).actGetLeads(reqbody);
    },

    formatDate(date: any) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${day}/${month}/${year}`;
    },
    formatTime(date: any) {
      if (!date) return null;
      const [fecha, hora] = date.split("T");
      let [hours, minutes, seconds] = hora.split(":");
      seconds = seconds.substr(-20, 2);
      return `${hours}:${minutes}:${seconds}`;
    },
    getCardPayload(columnId: any) {
      return (index: any) => {
        return this.cards.filter((p) => p.id === columnId)[0].children[index];
      };
    },

    showDetails(patient: any) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },

    OpenDialog(type: any, uuid: string) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.dialogLead = true;
          break;
      }
      this.uuidLead = uuid ? uuid : "none";
    },

    async addNote() {
      await (this as any).actAddNote({
        details: this.detailsnote,
        leadUuid: this.uuidLead,
      });
      (this as any).cancelDialog();
      (this as any).getLeads();
    },
    async addLead() {
      this.newlead.phone = "+1" + this.newlead.phone;
      await (this as any).actAddLead(this.newlead);
      (this as any).cancelDialog();
      (this as any).getLeads();
    },

    onCardDrop(value: any, valor: any) {
      console.log(value, valor);
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    cancelDialog() {
      this.dialogNote = false;
      this.dialogLead = false;
      (this as any).descriptioncall = "";
      this.detailsnote = "";
      this.newlead = {
        name: "",
        howKnow: "",
        last_name: "",
        phone: "",
        email: "",
      };
    },

    refresh(type: string) {
      switch (type) {
        case "dates":
          this.dateTo = "";
          this.dateFrom = "";
          this.body.dates = null;
          break;
        default:
          this.body.query = null;
          break;
      }
      (this as any).getLeads();
    },
  },
});
