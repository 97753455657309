



















































































import moment from "moment";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
export default Vue.extend({
  name: "crm-leads-calls",
  data() {
    return {
      tempLead: null,
      descriptioncall: "",
      callUuid:"",
      dialogCall: false,    
    };
  }, 
  computed: {
    ...mapState("crmConfigurationsModule", ["loading"]),
    ...mapState("crmCallCenterModule", ["callsLead","loadingfilter"]),
  },
  methods:{
    ...mapActions("crmConfigurationsModule", ["actAddDescription"]),    
      formatDate(date:any) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${day}/${month}/${year}`;
    },
       prettyDateShowTo(date:any) {
      if (date === null || date === "") return "";
      /*  if (this.filterRanges == "custom-select") {
        return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
      } */

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

     OpenDialog(value:string, descrp:string) { 
         this.descriptioncall = descrp 
        this.dialogCall = true;
        this.callUuid = value
    },

    
    async addDescription() {
      await this.actAddDescription({
        leadCallDescription: this.descriptioncall,
        callUuid: this.callUuid,
      });
      this.cancelDialog();
      this.$emit('descripadd');
    },

       cancelDialog() {
      this.dialogCall = false;   
      this.descriptioncall = "";
     
    },
  }
});
