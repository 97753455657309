<template>
  <div class="mt-1">
    <v-file-input
      v-model="files"
      hide-input
      counter
      accept="image/png, image/jpeg, image/bmp"
      multiple
      prepend-icon="mdi-image-album"
      show-size
      class="mb-3 mx-0"
      @change="setImages"
    >
      <template v-slot:selection="{}">
        <v-dialog v-model="dialog2" max-width="500px">
          <v-card>
            <v-card-title> Send </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(url, index) in urls"
                  :key="index"
                  class="d-flex child-flex"
                  cols="4"
                >
                  <v-card>
                    <v-img :src="url">
                      <v-app-bar flat color="rgba(0,0,0,0)">
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="deleteItem(index)"
                          color="error"
                          icon
                          fab
                        >
                          <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                      </v-app-bar>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <div justify-end>
                <v-btn color="error" text @click="closeDialog"> Close </v-btn>
                <v-btn color="primary" justify-end text @click="upLoadImages">
                  Send
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="uploadImgLoading"
          persistent
          hide-overlay
          width="300px"
        >
          <v-card color="primary" dark>
            <v-card-text>
              Uploading images, Please Wait!
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-file-input>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    contact: Object,
  },
  name: "crm-files-send",

  data: () => ({
    dialog2: false,

    files: [],
    urls: [],
  }),
  computed: {
    ...mapState("crmSMSModule", ["uploadImgLoading", "contactSelect"]),
  },
  methods: {
    ...mapActions("crmSMSModule", ["actUploadImage"]),
    setImages() {
      this.urls = [];
      this.dialog2 = true;
      this.urls = this.files.map((file) => {
        return URL.createObjectURL(file);
      });
    },
    deleteItem(index) {
      this.files.splice(index, 1);
      this.urls.splice(index, 1);
    },
    closeDialog() {
      this.dialog2 = false;
      this.files = [];
      this.urls = [];
    },
    async upLoadImages() {
      let number = "";
      if (this.contact != undefined) {
        number = this.contact.homePhone;
      } else {
        number = this.contactSelect.number;
      }
      await this.actUploadImage({
        file: this.files,
        number: number,
      });

      this.dialog2 = false;
    },
  },
  destroyed() {
    this.closeDialog();
  },
};
</script>
<style lang=""></style>
