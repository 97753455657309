<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="drawer-sms">
    <v-dialog
      v-model="showDrawer"
      persistent
      scrollable
      max-width="800"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon>
          <v-icon color="white">mdi-android-messages </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="d-flex flex-column ma-4"
            ><h3>{{ nameshow }}</h3>
            <span>{{ sms.number }}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="closedSms">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-overlay absolute :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <template>
          <section :loading="loading">
            <main>
              <div
                v-for="(msg, index) in _smsShow"
                v-bind:key="'index-' + index"
                :class="['message', sentOrReceived(msg.from)]"
              >
                <p v-show="msg.mediasUrl.length == 0">{{ msg.message }}</p>
                <v-img
                  class="img"
                  v-for="(media, index) in msg.mediasUrl"
                  :key="index"
                  :src="media"
                  @click="dialogImg(media)"
                  ><template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <v-dialog v-model="dialogImgShow" :width="width">
                  <v-card>
                    <v-img
                      :width="width"
                      :aspect-ratio="16 / 6"
                      :src="image"
                      lazy-src="https://picsum.photos/id/11/100/60"
                    >
                    </v-img>
                  </v-card>
                </v-dialog>
              </div>
              <div ref="scrollable1"></div>
            </main>
          </section>
        </template>
        <message-input :loading="loading" @send-message="sendMessage" />
      </v-card>
    </v-dialog>
    <!--     <v-navigation-drawer
      v-model="showDrawer"
      right
      absolute
      temporary
      class="mt-5"
      width="400"
      :style="{ height: '850px' }"
    >
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> {{ name }}</v-list-item-title>
          <v-list-item-subtitle>{{ sms.number }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <section :loading="loading">
        <main>
          <div
            v-for="(msg, index) in _smsShow"
            v-bind:key="'index-' + index"
            :class="['message', sentOrReceived(msg.from)]"
          >
            <p v-show="msg.mediasUrl.length == 0">{{ msg.message }}</p>
            <v-img
              class="img"
              v-for="(media, index) in msg.mediasUrl"
              :key="index"
              :src="media"
              @click="dialogImg(media)"
              ><template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row> </template
            ></v-img>
            <v-dialog v-model="dialogImgShow" :width="width">
              <v-card>
                <v-img
                  :width="width"
                  :aspect-ratio="16 / 6"
                  :src="image"
                  lazy-src="https://picsum.photos/id/11/100/60"
                >
                </v-img>
              </v-card>
            </v-dialog>
          </div>
          <div ref="scrollable1"></div>
        </main>
       
      </section>
    </v-navigation-drawer> -->
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import MessageInput from "@/views/SMS/Messages/MessageInput.vue";
import formatPhone from "@/utils/formatPhone";
import { getAPI } from "@/api/axios-base";
export default Vue.extend({
  components: { MessageInput },
  name: "crm-sms-drawer",
  props: {
    details: Object,
    origin: String,
    showSms: Boolean,
    leadUuid: String,
  },
  data() {
    return {
      loading: false,

      name: "",
      showDrawer: false,
      activeSms: null,
      sms: {
        number: "",
        message: "",
        // replyTo: "+17869461441",
      },
      dialogImgShow: false,
      image: "",
      width: 800,
    };
  },
  computed: {
    ...mapState("crmSMSModule", [
      "messagesPatient",
      "messages",
      "contactSelect",
    ]),
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    nameshow() {
      if (this.origin == "lead") {
        return this.details.name + " " + this.details.last_name;
      }
      if (this.origin == "patient" && this.patientDetail != null) {
        return this.patientDetail.fullname;
      }
      return "";
    },
    _smsShow() {
      if (this.messages.length == 0) {
        return [];
      }
      const contacto = this.contactSelect;
      if (contacto != null) {
        const msg = this.messages.filter(
          (sms) => sms.from == contacto.number || sms.to == contacto.number
        );

        return msg;
      } else {
        return [];
      }
    },
  },
  watch: {
    showDrawer(val) {
      if (val) {
        this.loading = true;
        this.initSms();
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      }
    },
  },

  methods: {
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutSetMessagesPatient",
      "mutSetMessages",
    ]),
    ...mapActions("crmSMSModule", ["actListMessages", "actSendMessage"]),
    getMessages() {
      const tempsms = this.messages;

      this.actListMessages().then(() => {
        if (tempsms.length != this.messages.length) {
          this.scrolltoBotton();
        }
      });
    },
    async sendMessage() {
      this.getMessages();
    },

    closedSms() {
      this.showDrawer = false;
      this.stopSms();
    },

    sentOrReceived(numberFrom) {
      return numberFrom === this.$store.state.profile.phone
        ? "sent"
        : "received";
    },

    scrolltoBotton() {
      /* this.$refs["scrollable1"].scrollIntoView({
        behavior: "smooth",
      }); */
    },

    dialogImg(image) {
      this.image = image;
      this.dialogImgShow = true;
    },

    initSms: function () {
      this.mutSetMessagesPatient([]);
      if (this.origin == "lead") {
        let leadphone = this.details.phone;

        if (leadphone.includes("+1")) {
          leadphone = leadphone.replace("+1", "");
        }
        leadphone = leadphone.replaceAll("\t", "");
        leadphone = leadphone.replaceAll(" ", "");
        leadphone = formatPhone(leadphone);
        leadphone = "+1" + leadphone;

        this.sms.number = leadphone;
        this.name = this.details.name;
      } else {
        let patientphone = this.patientDetail.homePhone;

        if (patientphone.includes("+1")) {
          patientphone = patientphone.replace("+1", "");
        }
        patientphone = patientphone.replaceAll("\t", "");
        patientphone = patientphone.replaceAll(" ", "");
        patientphone = formatPhone(patientphone);
        patientphone = "+1" + patientphone;

        this.sms.number = patientphone;

        this.name = this.patientDetail.firstName;
      }
      this.mutSetContact({
        number: this.sms.number,
        fullname: this.name,
      });
      this.showDrawer = true;

      this.scrolltoBotton();
      this.activeSms = setInterval(this.getMessages, 1000);
    },
    stopSms: function () {
      this.mutSetMessages([]);
      clearInterval(this.activeSms);
      this.showDrawer = false;
    },
  },
});
</script>
<style lang="scss" scope>
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 785px !important;
  background-color: rgb(238, 237, 243);

  main {
    padding: 10px;
    height: 95% !important;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: #1e1e24;
    }

    &::-webkit-scrollbar-thumb {
      background: #6649b8;
    }
  }

  form {
    height: 5vh;
    position: fixed;
    bottom: 0;
    background-color: rgb(24, 23, 23);
    width: 100%;
    max-width: 728px;
    display: flex;
    font-size: 1.5rem;

    button {
      width: 20%;
      background-color: rgb(56, 56, 143);
    }

    input {
      line-height: 1.5;
      width: 100%;
      font-size: 1.5rem;
      background: rgb(58, 58, 58);
      color: white;
      outline: none;
      border: none;
      padding: 0 10px;
    }
  }

  .message {
    display: flex;
    align-items: center;

    &.received {
      p {
        background: #e5e5ea;
        color: #000;
      }
    }

    &.sent {
      flex-direction: row-reverse;

      p {
        color: #fff;
        background: #0b93f6;
        align-self: flex-end;
      }
    }

    .img {
      width: 100px;
      height: 80px;
      max-height: 80px;
      max-width: 100px;
      margin: 2px 5px;
    }

    p {
      max-width: 500px;
      margin-bottom: 12px;
      line-height: 24px;
      padding: 10px 20px;
      border-radius: 25px;
      position: relative;
      color: #fff;
      text-align: justify;
    }
  }

  button,
  input {
    color: #fff;
    border: none;
  }

  p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
  }
}
</style>
