<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-row class="img" align="center" justify="center">
      <template v-if="!loading">
        <v-img
          max-height="140"
          :src="urlimg"
          contain
          max-width="100"
          @click="dialog = true"
        >
        </v-img>
      </template>
      <template v-else>
        <div class="d-flex justify-center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </template>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div class="dialogimg">
        <v-img :src="urlimg" contain></v-img>
        <v-btn fab small class="btnimg" @click="dialog = false"
          ><v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "card-image",
  props: {
    url: String,
    active: Boolean,
  },
  data() {
    return {
      overlay: false,
      dialog: false,
      loading: false,
      urlimg: "",
    };
  },
  methods: {
    getImage() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      this.loading = true;
      axios({
        method: "get",
        url: this.url,
        responseType: "blob",
        headers: auth,
      })
        .then((res) => {
          const buffer = new Blob([res.data]);
          this.urlimg = URL.createObjectURL(buffer);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getImage();
  },
};
</script>
<style lang="scss" scoped>
.img {
  margin: 5px;
}
.dialogimg {
  position: relative;
}
.btnimg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
</style>
