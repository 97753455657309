<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-sheet class="mx-auto" elevation="8" max-height="200">
    <template v-if="images.length == 0 && loading">
      <div class="d-flex justify-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </template>
    <template v-else-if="images.length == 0 && !loading">
      <div class="noimage">
        <span class="ma-4 text-center"> No images !!</span>
      </div>
    </template>
    <template v-else>
      <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
        <v-slide-item
          v-for="(n, i) in images"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-card
            :color="active ? 'primary' : 'grey lighten-1'"
            class="ma-4"
            height="150"
            width="100"
            @click="toggle"
          >
            <card-image :url="n.url" />
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </template>
  </v-sheet>
</template>
<script>
import CardImage from "@/components/Images/CardImage.vue";
import { getAPI } from "@/api/axios-base";
import { apiBaseUrl } from "@/enviorment";

export default {
  name: "all-images-patient",
  components: { CardImage },
  props: {
    imagesfrom: String,
    uuid: String,
  },
  data() {
    return {
      loading: false,
      model: null,
      images: [],
    };
  },
  methods: {
    getImages() {
      this.loading = true;
      if (this.imagesfrom == "patient") {
        getAPI
          .get(apiBaseUrl + "/patient/mediasFromPatient/" + this.uuid)
          .then((res) => {
            this.loading = false;
            this.images = res.data;
            if (this.images.length != 0) {
              this.images = this.images.map((ima) => {
                return { url: ima };
              });
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        getAPI
          .get(apiBaseUrl + "/social/mediasFromLead/" + this.uuid)
          .then((res) => {
            this.loading = false;
            this.images = res.data;
            if (this.images.length != 0) {
              this.images = this.images.map((ima) => {
                return { url: ima };
              });
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getImages();
  },
  destroyed() {
    this.images = [];
    this.model = null;
  },
};
</script>
<style lang="scss" scoped>
.noimage {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
